import React from 'react';
import { Link } from 'react-router-dom/cjs/react-router-dom.min';

const HeaderConfig = () => {
    const LoginRegHandler = () => {
        const offCanvasConfig = document.querySelector('.off-canvas-cog');
        offCanvasConfig.classList.add('active');
        document.querySelector('body').classList.add('fix');
    }

    const MobileMenuHandler = () => {
        const offCanvasMenu = document.querySelector('.off-canvas-menu');
        offCanvasMenu.classList.add('active');
    }

     return (
         <div className="header-action mt-lg-0 text-end">
            {/* <Link to={process.env.PUBLIC_URL + '/contact'} className="">Contact Us</Link> */}
            <Link to={`${process.env.PUBLIC_URL + '/contact'}`} className="btn-navigation-menu">Contact Us</Link>
            {/* <a href="/contact" rel="noopener noreferrer"><button className="btn-navigation-menu">Contact Us</button></a> */}
            {/* <a href="tel:00199823568658" className="tel-no">+998 23568 658</a> */}
             {/* <button onClick={LoginRegHandler} className="btn-cog"><i className="fa fa-cog"/></button>  */}
             <button onClick={MobileMenuHandler} role="button" className="btn-menu d-lg-none" id="burger" aria-label="Burger"><i className="fa fa-bars"/></button>
       </div>
     );
}

export default HeaderConfig;






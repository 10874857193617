import React from 'react';
import Content from "../../UI/Content";
import Thumbnail from "../../UI/Thumbnail";
import dividerImg from './heading-divider.png';
import buildSomethingThumb from './cmu-services-build-something-with-us.jpg'

const About = ({title,heading,content,thumb}) => {
    return (
        <div className="page-about-wrapper sm-top">
            <div className="container">
                <div className="row align-items-lg-center">
                {/* <div className="row align-items-bottom"> */}
                    <div className="col-md-6 col-lg-6 order-1-services">
                        <Content classes="about-content ml-0">
                            {/* <h6>{title}</h6> */}
                            <h2 dangerouslySetInnerHTML={{__html: heading}}/>
                            <div className="heading-divider-image-div"><img width="120" height="7" className="divider-image-services" src={dividerImg} alt="cmu-divider" /></div>
                            <p dangerouslySetInnerHTML={{__html: content}}/>
                        </Content>
                    </div>

                    <div className="col-md-6 col-lg-6 order-0-services order-md-1">
                        {/* <Thumbnail width="100%" height="100%" alt="cmu-build-something-with-us" classes="build-something-with-us-thumb" imgSrc={thumb}/> */}
                        <img width="100%" height="auto" className="build-something-with-us-thumb" src={buildSomethingThumb} alt="cmu-divider" />
                    </div>
                </div>
            </div>
            <div className="container sm-top">
            <h2 className="h2-lg-device-why-work-with-cmu">Why Work with Code Me Up?</h2>
            <h2 className="h2-sm-device-why-work-with-cmu">Why Work with<br /> Code Me Up?</h2>
            </div>
        </div>
    );
};

export default About;
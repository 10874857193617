import React from 'react';

function FeatureItem(props) {
    return (
        
        <div className="col-md-4">
            
            <div className="icon-box-item">
                {/* <div className="icon-box__icon icon-box__icon-services"> */}
                    {/* <img src={require('../../assets/img/' + props.img)} alt="Businex-Feature"/> */}
                {/* </div> */}
                <div className="icon-box__info-services">
                    <h3>{props.title}</h3>
                    <p>{props.text}</p>
                </div>
                
            </div>
        </div>
    );
}

export default FeatureItem;
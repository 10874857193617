import React,{Fragment} from 'react';
import Header from "../components/Header";
import PageHeader from "../components/PageHeaderServices";
import PageAbout from "../components/About/page";
import Services from "../components/Services";
import PricingTable from "../components/PricingTable";
import Testimonial from "../components/Testimonials/home-two";
import BrandLogo from "../components/BrandLogo";
import Funfact from "../components/Funfact";
import CallToAction from "../components/CallToAction";
import Footer from "../components/Footer";
import LoginRegister from "../components/LoginRegister";
import MobileMenu from "../components/MobileMenu";

import ServiceThumb from '../assets/img/codemeup-services-build-something-with-us.jpg'
import ServicesFeatures from '../components/ServicesFeatures';
import dividerImg from './heading-divider.png';


const PageService = () => {
    return (
        <Fragment>
            <Header/>
            <PageHeader
                bgImg={require('../assets/img/page-header.jpg')}
                title="OUR SERVICES"
                content="Businex always try to provide the best Business Solutions for Clients to grow up their Business very sharply and smoothly."
            />
            <div >
            <PageAbout classes="align-items-bottom"
                // title={'Our Services'}
                
                heading="Build Something With Us"
                thumb={ServiceThumb}
                content="Our team is ready to collaborate with you in creating innovative tools and solutions that address current challenges and anticipate future ones, facilitating your organization's continued growth. We have expertise in a wide range of software languages, frameworks, and technologies."

            />
            </div>
            
            <ServicesFeatures />
            <Services classes="sm-top sm-top"/>
            {/* <PricingTable/> */}
            {/* <Testimonial/> */}
            {/* <BrandLogo/> */}
            {/* <Funfact classes="sp-top"/> */}
            <CallToAction/>
            <Footer/>
            <LoginRegister/>
            <MobileMenu/>
        </Fragment>
    );
};

export default PageService;
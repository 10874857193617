// import From from "./From";
import ContactInfo from "./ContactInfo";
// import Head from 'next/head';
// import { HeadProvider, Title, Link, Meta } from 'react-head';
import { Helmet, HelmetProvider } from 'react-helmet-async'
import { useFormik } from "formik";
import * as yup from "yup";
import axios from "axios";
import { Alert } from "@mui/material";
import { useState } from "react";





const ContactPage = (event) => {
  const [formSubmitted, setFormSubmitted] = useState(false);
  const [error, setError] = useState(false);

  const formik = useFormik({
    initialValues: {
      name: "",
      email: "",
      phone_number: "",
      message: "",
      // country: "United Kingdom",
      // terms: "",
    },

    validationSchema: yup.object({
      name: yup
        .string()
        .max(20, "Name must be 20 characters or less.")
        .required("Please complete this required field"),
      email: yup
        .string()
        .email("Invalid E-mail address")
        .required("Please complete this required field"),
      //   terms: Yup.array().required("Terms of service must be checked"),
      //   phone_number: yup.string().required("Phone number is required"),
      //   phone_number: yup.string(),
      message: yup.string().required("Please fill out the message field"),
    }),

    // onSubmit: async (values, { resetForm }) => {
    onSubmit: async (values, {}) => {
      setFormSubmitted(true);
      try {
        const response = await axios({
          method: "post",
          // url: "http://192.168.2.127:3005/email/contact",
          // url: "http://mcs01.codemeup.net:8092/email/contact",
          url: "https://cms.codemeup.net/email/contact",
          headers: {
            "x-api-key": "91300060-4db9-4474-93a2-9c7133ba1f49",
          },
          data: {
            name: values.name,
            email: values.email,
            phone_number: values.phone_number,
            message: values.message,
          },
        });
        // TODO: Handle response (200)

        formik.resetForm({});
        // event.preventDefault();
      } catch (exception) {
        setError(true);
        console.log(exception)
      }

      //   return alert(`Message sent to ${values.name}`);
      //   console.log("form submitted");
      //   console.log(values);
      //   router.push({ pathname: "/success", query: values });
    },
  });

  return (

<div>
<HelmetProvider>
 <Helmet>
 <meta http-equiv="Content-Security-Policy" content="upgrade-insecure-requests" />
 </Helmet>
 </HelmetProvider>
    <div className={"contact-page-area-wrapper sp-y sm-top"}>
      <div className="container">
        <div className="contact-content-wrap">
          <div className="row">
            <div className="col-lg-8 col-lg-8-contact-us-form">
              <div className="contact-form-area contact-method">
                <h1>Contact Us</h1>
                <p>
                  We are here to answer any questions you may have.
                  <br /> Feel free to contact us, and we will reply as soon as
                  possible.
                </p>
                {/* <From/> */}

                {/* <main className="  h-screen items-center flex justify-center"> */}
                <div className="contact-form-wrap">
                  <form
                    id="contact-form"
                    onSubmit={formik.handleSubmit}
                    className=""
                  >
                    {/* <h1 className="text-3xl pb-2 font-latoBold">
                Let's get started 👋
              </h1>
              <p className="text-lg  text-gray-500">
                Join our E-learning platform today and unlock over 500+ courses
                and digital assets ready to download.
              </p> */}
                    <div className="row">
                      {/* Name input field */}
                      <div className="col-md-12">
                        <div className="single-input-item-2">
                          <label
                            htmlFor="name"
                            className={`block pb-2 ${
                              formik.touched.name && formik.errors.name
                                ? "text-red-400"
                                : ""
                            } `}
                          >
                            {formik.touched.name && formik.errors.name
                              ? formik.errors.name
                              : "Name"}
                          </label>
                          <p className="text-red-400 "></p>
                          <input
                            type="text"
                            name="name"
                            placeholder="Enter your name"
                            onChange={formik.handleChange}
                            value={formik.values.name}
                            onBlur={formik.handleBlur}
                          />
                        </div>
                      </div>
                      {/* Email input field */}
                      <div className="single-input-item-2 col-md-6">
                        <label
                          htmlFor="email"
                          className={`block pb-2 ${
                            formik.touched.email && formik.errors.email
                              ? "text-red-400"
                              : ""
                          }`}
                        >
                          {formik.touched.email && formik.errors.email
                            ? formik.errors.email
                            : "Email"}
                        </label>

                        <p className="text-red-400 "></p>
                        <input
                          type="text"
                          name="email"
                          placeholder="Enter your email address"
                          onChange={formik.handleChange}
                          value={formik.values.email}
                          onBlur={formik.handleBlur}
                        />
                      </div>

                      {/* Mobile phone input field */}
                      <div className="single-input-item-2 col-md-6">
                        <label
                          htmlFor="phone_number"
                          className={`block pb-2 ${
                            formik.touched.phone_number &&
                            formik.errors.phone_number
                              ? "text-red-400"
                              : ""
                          }`}
                        >
                          {formik.touched.phone_number &&
                          formik.errors.phone_number
                            ? formik.errors.phone_number
                            : "Phone number"}
                        </label>

                        <p className="text-red-400"></p>
                        <input
                          type="number"
                          name="phone_number"
                          placeholder="Enter your phone number"
                          onChange={formik.handleChange}
                          value={formik.values.phone_number}
                          onBlur={formik.handleBlur}
                        />
                      </div>
                      {/* Text area - Message field */}
                      <div className="single-input-item-2 col-md-12">
                        <label
                          htmlFor="message"
                          className={`block pb-2 ${
                            formik.touched.message && formik.errors.message
                              ? "text-red-400"
                              : ""
                          }`}
                        >
                          {formik.touched.message && formik.errors.message
                            ? formik.errors.message
                            : "Message"}
                        </label>

                        <p className="text-red-400 "></p>
                        <textarea
                          name="message"
                          placeholder="Enter Your message"
                          colls="30"
                          rows="7"
                          onChange={formik.handleChange}
                          value={formik.values.message}
                          onBlur={formik.handleBlur}
                        />
                      </div>

                      {formSubmitted && !error ? (
                        <Alert
                          className="contact-form-success-message"
                          severity="success"
                        >
                          Your message has been sent!
                        </Alert>
                      ) : 
                        formSubmitted &&
                        error ?
                          <Alert
                            className="contact-form-success-message"
                            severity="error"
                          >
                            Oops, something went wrong!
                          </Alert>
                        : <></>
                      }

                      <button
                        // onClick={notify}
                        type="submit"
                        value="send message"
                        className="btn-outline"
                        data-umami-event="Send message button"
                      >
                        Send Message
                      </button>

                      {/* <ToastContainer /> */}
                    </div>

                    {/* <div className="relative flex-1">
              <Image
                className=" object-cover rounded-lg"
                fill
                priority
                src={formImage}
                alt="form-learn"
              />
            </div> */}
                  </form>
                </div>
                {/* </main> */}
              </div>
            </div>
            <div className="col-lg-4">
              <div className="contact-information contact-method">
                <div className="contact-info-con">
                  <h2>CONTACT</h2>
                  <ContactInfo
                  // address="Dušana Vukasovića 82, Belgrade"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    </div>
  );
};

export default ContactPage;

// const ContactPage = () => {
//     return (
//         <div className={'contact-page-area-wrapper sp-y sm-top'}>
//             <div className="container">
//                 <div className="contact-content-wrap">
//                     <div className="row">
//                         <div className="col-lg-8">
//                             <div className="contact-form-area contact-method">
//                                 <h3>Contact Us</h3>
//                                 <p>We are here to answer any questions you may have.<br /> Feel free to contact us, and we will reply as soon as possible.</p>
//                                 {/* <From/> */}

//                             </div>
//                         </div>

//                         <div className="col-lg-4">
//                             <div className="contact-information contact-method">
//                                 <div className="contact-info-con">
//                                     <h3>CONTACT</h3>
//                                     <ContactInfo
//                                         // address="Dušana Vukasovića 82, Belgrade"
//                                     />
//                                 </div>
//                             </div>
//                         </div>
//                     </div>
//                 </div>
//             </div>
//         </div>
//     );
// };

// export default ContactPage;

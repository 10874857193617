
import React, {useState} from 'react';
import {Link} from "react-router-dom";
import parse from "html-react-parser";
import Thumbnail from "../../UI/Thumbnail";
import Content from "../../UI/Content";
import aboutData from '../../../data/About/home-one';
import dividerImg from '../../../assets/img/cmu-h-divider.jpg'
// import { CgArrowLongRight } from 'react-icons/cg';
import thumb1 from "../../../assets/img/cmu-about-us-img-1.png"
// import thumb2 from "../../../assets/img/cmu-about-us-2.jpg"
// import thumb3 from "../../../assets/img/cmu-about-us-3.jpg"
// import thumb4 from "../../../assets/img/cmu-about-us-4.jpg"


const About = () => {

    const [about] = useState(aboutData);

    return (
        <div className="about-area-wrapper sm-top sm-tom-mobile">
            <div className="container">
                <div className="row align-items-lg-center">
                <div className="col-md-12 col-lg-6">
                        <Content classes="about-content">
                            {/* <h6>{about.title}</h6> */}
                            <h2>{parse(about.heading)}</h2>
                            <div className="heading-divider-image-div"><img width="120" height="7"  className="divider-image" src={dividerImg} alt="cmu-divider" /></div>
                            {/* <span className="about-since">{about.since}</span> */}
                            <p>{parse(about.text)}</p>
                            
                            {/* <Link to={`${process.env.PUBLIC_URL + about.btnLink}`}
                                  className="btn-about">{about.btnText}<CgArrowLongRight className="arrow-icon"/></Link> */}
                            <Link to={`${process.env.PUBLIC_URL + about.btnLink}`}
                                  className="btn-about">{about.btnText}</Link>



                        </Content>
                    </div>
                    <div className="col-md-6 col-lg-6 col-xl-6 col-xl_6 col-md_6 ">                   
                    <div className="col-sm-12 col-md-12 col-lg-12">
                        {/* <Thumbnail classes="about-thumb-1" imgSrc={require('../../../assets/img' + about.thumb)}/> */}
                        {/* <img src={thumb1} className="about-thumb-1" alt="about-thumb" /> */}
                        <img className="about-us-thumb" width="556" height="100%" src={thumb1} alt="transform-your-digital-vision-with-us" />
                        {/* <img width="300" height="205" src={thumb2} className="about-thumb-2" alt="about-thumb" /> */}

                    </div>
                    {/* <div className="col-sm-6 col-md-6 col-lg-6 icon-box-item about-icon-box-item about-thumb-div-2 "> */}
                        {/* <Thumbnail classes="about-thumb-3" imgSrc={require('../../../assets/img' + about.thumb)}/> */}
                        {/* <img width="300" height="205" src={thumb3} className="about-thumb-3" alt="about-thumb" /> */}
                        {/* <img width="300" height="205" src={thumb4} className="about-thumb-4" alt="about-thumb" /> */}
                    {/* </div> */}
                </div>
                
                    
                    
                </div>
            </div>
        </div>
        
    );
};

export default About;
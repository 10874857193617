import React from 'react';
import SectionTitle from '../UI/SectionTitle'
import ServiceItem from  './ServiceItem'
// import ServicesData from '../../data/Services/services'
// import dividerImg from '../../assets/img/heading-divider-test.png';
import serviceTopBg from '../../assets/img/service/cmu-services-from-product-deisgn-to-development-continuous-delivery.jpg'


import { useEffect, useState } from "react";
import axios from "axios";



const Services = ({classes}) => {

    const [error, setError] = useState(null);
    const [services, setServices] = useState([]);
      useEffect(() => {
        
          async function getData() {
              const response = await axios({
                  method: "get",
                  // url: "http://192.168.2.127:3005/email/contact",
                  // url: "http://mcs01.codemeup.net:8092/email/contact",
                  url: "https://cms.codemeup.net/api/services/?sort[0]=id:asc",
                  headers: {
                    Authorization:
                       'Bearer f60e884f8988f121bfe2bbd21181b58ce11655172e593c35cb9787fc526b6897114e4c99bf56bb75541b06abc01d70de7e497b39ced3af0f82a78a95953520de1bfd4781572dcabf0ab855e8683ef3af6bd5ef064fecf25e59c6614371c32c3c6eecd6e0ce0655da41a5fa3d3718d7f48921ebccd12c3821ee5088b2addd92ab',
                  },
                  
                });

                const {data} = response.data;
                setServices(data);
                console.log(data);
          };
          getData();
            }, []);
    return (
        <div className={`service-area-wrapper ${classes}`}>
            <div className="service-area-top" style={{backgroundImage: `url("${serviceTopBg}")`, backgroundRepeat: 'no-repeat', backgroundPosition: 'center', backgroundSize: 'cover'}}>
                <div className="container">
                    <div className="row">
                        <div className="col-lg-6 col-xl-5 m-auto text-center">
                            <SectionTitle variant="light" title="Our Services" heading="From Product Design to Development, Continuous Delivery" />
                            {/* <div className="heading-divider-image-div"><img className="divider-image" src={dividerImg} alt="horse" /></div> */}
                        </div>
                    </div>
                </div>
            </div>

            <div className="service-content-area">
                <div className="container">
                    <div className="row mtn-30">
                        {/* {
                            ServicesData.map(service=>(
                                <ServiceItem key={service.id} id={service.id} title={service.title} text={service.shortDesc} thumb={service.thumb}/>
                            ))
                        } */}
                        {
                            services.map(service=>(
                                <ServiceItem key={service.id} id={service.id} title={service.attributes.title} alt={service.attributes.altDesc} description={service.attributes.description} thumb={service.attributes.thumb_img}/>
                            ))
                        }
                        <div>
      {/* <div>
        {services.map((service, index) => (
          <p key={`service-${index}`}>{service?.attributes.Name}</p>
        //   <p key={service}>{service?.attributes.Description}</p>
        ))}
      </div> */}
    </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Services;
import React from 'react';
import Text from "../UI/Text";
import Widget from "../UI/Widget";
import List from "../UI/List";
import LI from "../UI/List/Item";
import {Link} from "react-router-dom";
// import Logo from '../../assets/img/logo-dark.png'
import Logo from '../../assets/img/cmu-logo-black-red-footer.png'
import backgroundImg from '../../assets/img/codemeup-footer-globe.png'
import socialNetworks from '../../data/SocialNetworks/socials'
import { AiOutlineLinkedin } from 'react-icons/ai';

function Footer() {
    return (
        <footer className="footer-area sp-bottom-footer sp-top" style={{ backgroundImage: `url(${backgroundImg})`, backgroundRepeat: 'no-repeat', backgroundPosition: 'right', backgroundSize: 'contain'}}>
            <div className="container">
                <div className="row mtn-40">
                    <div className="col-lg-4 order-4 order-lg-0">
                        <div className="widget-item">
                            <div className="about-widget">
                                <Link to={`${process.env.PUBLIC_URL + "/"}`}>
                                    <img src={Logo} alt="Logo"/>
                                </Link>

                                <Text> 
                                     Delivered TV products and features running on millions of active devices that are used by millions of daily active users.
                                </Text>

                                
                            </div>
                        </div>
                    </div>

                    <div className="col-md-5 col-lg-4 ml-auto">
                        <Widget title="Services">
                            {/* <List classes="widget-list"> */}
                                {/* <LI><Link to={`${process.env.PUBLIC_URL + "/"}`}>Android TV app development</Link></LI>
                                <LI><Link to={`${process.env.PUBLIC_URL + ""}`}>SmartHome, IoT adn embeded systems</Link></LI>
                                <LI><Link to={`${process.env.PUBLIC_URL + ""}`}>OTT Media Player development</Link></LI>
                                <LI><Link to={`${process.env.PUBLIC_URL + ""}`}>Backend services and APIs</Link></LI>
                                <LI><Link to={`${process.env.PUBLIC_URL + ""}`}>CI/CD, QA and test automation</Link></LI>
                                <LI><Link to={`${process.env.PUBLIC_URL + ""}`}>Analytics and Advertising</Link></LI> */}
                                {/* </List> */}
                            <ul classes="widget-list">
                                <li classname="footer-services-li">Android TV App Development</li>
                                <li classname="services-paragraph">SmartHome, IoT and Embedded Systems</li>
                                <li classname="services-liaragralih">OTT Media lilayer Development</li>
                                <li classname="services-paragraph">Backend Services and APIs</li>
                                <li classname="services-paragraph">CI/CD, QA and Test Automation</li>
                                <li classname="services-paragraph">Analytics and Advertising</li>
                            
                            </ul>
                        </Widget>
                    </div>

                    {/* <div className="col-md-4 col-lg-2 ml-auto">
                        <Widget title="Social Links">
                            <List classes="widget-list">
                                <LI><Link to="https://facebook.com/hasthemes/" target={'_blank'}>Facebook</Link></LI>
                                <LI><Link to="https://twitter.com/hasthemes/" target={'_blank'}>Twitter</Link></LI>
                                <LI><Link to="https://dribbble.com/hastech/" target={'_blank'}>Dribbble</Link></LI>
                                <LI><Link to="https://www.instagram.com/" target={'_blank'}>Instagram</Link></LI>
                            </List>
                        </Widget>
                    </div> */}

                    <div className="col-md-4 col-lg-4">
                        <Widget title="Get in Touch With Us">
                            <footer-address>
                        
                                Dušana Vukasovića 82, Belgrade<br/>
                                <a href="https://www.codemeup.net" target="_blank">codemeup.net</a>
                                 <br/>
                                <a href="mailto:office@codemeup.net">office@codemeup.net</a>
                                <div className="member-social-icons mt-10">
                {
                    socialNetworks.map(social=>(
                        <a key={social.id} href={`https://${social.networkName}.com/${social.username}`} target="_blank" rel="noopener noreferrer" aria-label="Connect with Us on LinkedIn" >
                            {/* <i className={`fa fa-${social.networkName}`}/> */}
                            <AiOutlineLinkedin className="linkedin-ico-footer"/>
                       
                            
                            
                        </a>
                    ))
                }
            </div>
                            </footer-address>
                        </Widget>
                    </div>
                </div>

                <div>
            <Text classes="copyright-txt">
                                    &copy; {new Date().getFullYear()} Code Me Up. All Rights Reserved.
                                </Text>
            </div>

            </div>
           
        </footer>
    );
}

export default Footer;
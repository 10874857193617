import React from 'react';
import {Link} from "react-router-dom";



function ServiceItem(props) {

    // const imageURL = props?.attributes.thumb_img ? props?.attributes.thumb_img : '';
     return (
        <div className="col-sm-6 col-lg-4">
            <div className="service-item">
                <figure className="service-thumb">
                    
                    {/* {props?.thumb ? <Link to={`${process.env.PUBLIC_URL + serviceURL}`}>
                        <img src={props?.thumb} alt={props?.text} height={300}/>
                    </Link> : <></> }  */}
                    {/* {props?.thumb ? <Link to={`${process.env.PUBLIC_URL}`}>
                        <img src={props?.thumb} alt={props?.text} height={300}/>
                    </Link> : <></> }  */}
                    <img src={props?.thumb} alt={props?.description} height={300} width={388}/>
                    <figcaption className="service-txt">
                        <h3>{props?.title}</h3>
                    </figcaption>
                </figure>
                <div className="service-content">
                    <div className="service-content-inner">
                        {/* <h5>
                            <Link to={`${process.env.PUBLIC_URL + serviceURL}`} className="stretched-link">{props.title}</Link>
                        </h5> */}
                        <p>{props?.description}</p>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default ServiceItem;
import React from 'react';
import PageHeaderData from '../../data/PageHeader/home-1'
import {Link} from "react-router-dom";
import { CgArrowLongRight } from 'react-icons/cg';


const PageHeader = () => {



    return (

        PageHeaderData.map(item => (
      <div className="hero-area-wrapper sm-top">
        <div key={item.id} className="page-header-area bg-img" style={{backgroundImage: `url(${require('../../assets/img' + item.backgroundImage)})`,
        backgroundPosition: 'center',}}>

            <div  className="container">
            
                <div className="row">
                    {/* <div className="col-lg-10 col-xl-8 m-auto text-center"> */}
                    <div className="m-auto text-center">
                        <div className="page-header-content-inner">
                            <div className="page-header-content col-xl-7_5" style={{marginTop: '2rem'}}>
                                <h1>{item.title}</h1>
                                <p className="hero-description-content">{item.content1}</p>
                                <p className="hero-description-content-2">{item.content2}</p>
	{/* <div className="col-xl-4_5 btn-hero-div" style={{marginBottom: '2rem'}}><Link to={`${process.env.PUBLIC_URL + item.btnLink2}`} className="btn btn-hero">{item.btnText2}</Link></div> */}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
</div>
        ))
    );
};



export default PageHeader;
import React from 'react';
import parse from 'html-react-parser'
import CallToActionData from '../../data/CallToAction/call-to-action'
import {Link} from "react-router-dom";
import backgroundImg from "../../assets/img/cmu-transform-your-digital-vision-into-reality.jpg"



function CallToAction() {
    return (
        // style={{ backgroundImage: `url(${testSVG})`}
        <div className="call-top-action-wrap sp-y blob" style={{ backgroundImage: `url(${backgroundImg})`, backgroundRepeat: 'no-repeat', backgroundPosition: 'center' }}>
            <div  className="container">
                <div className="footer-top-content">
                    <div className="row align-items-center">
                        <div className="col-md-8 col-lg-6">
                            <h2 className="h-tag-call-to-action">{CallToActionData.title}</h2>
                            <p className="p-tag-call-to-action">{parse(CallToActionData.text)}</p>
                    
                        </div>
                        <div className="col-md-4 col-lg-6 text-md-end mt-sm-25">
                            <Link to={`${process.env.PUBLIC_URL + CallToActionData.btnLink}`} className="btn-outline btn-transparent-white">{CallToActionData.btnText}</Link>
                        </div>
                    </div>
                </div>
            </div>
            <div>

            </div>
        </div>
    );
}

export default CallToAction;
import React, { Fragment } from "react";
import socialNetworks from "../../data/SocialNetworks/socials";
import { FaEnvelope } from "react-icons/fa";
import { FaDiceD20 } from "react-icons/fa";
import { FaMapMarkerAlt } from "react-icons/fa";
import { AiOutlineLinkedin } from "react-icons/ai";

const ContactInfo = ({ address }) => {
  return (
    <Fragment>
      <div className="widget-item m-0">
        <address>
          <span dangerouslySetInnerHTML={{ __html: address }} />
          <div>
            <FaMapMarkerAlt className="me-1" />{" "}
            <a
              href="https://www.google.com/maps/place/Du%C5%A1ana+Vukasovi%C4%87a+80,+Beograd+11070/@44.8030444,20.3698,18z/data=!4m15!1m8!3m7!1s0x475a6f8316e444ef:0x1e8134520289eef9!2sDu%C5%A1ana+Vukasovic%CC%81a+82,+Beograd+11070!3b1!8m2!3d44.8030406!4d20.3710874!16s%2Fg%2F11ry7s3hy9!3m5!1s0x475a6f6ce743e95b:0xa2d74c64c26e0878!8m2!3d44.8032291!4d20.3710477!16s%2Fg%2F11kx2r02fc?entry=ttu"
              target="_blank"
            >
              Dušana Vukasovića 82, Belgrade
            </a>
          </div>
          <div>
            <FaDiceD20 className="me-1" />{" "}
            <a href="https://www.codemeup.rs" target="_blank">
              codemeup.rs
            </a>
          </div>
          <div>
            <FaEnvelope className="me-1" />{" "}
            <a href="mailto:office@codemeup.net">office@codemeup.net</a>
          </div>
        </address>
      </div>
      <div className="member-social-icons mt-30">
        {socialNetworks.map((social) => (
          <a
            key={social.id}
            href={`https://${social.networkName}.com/${social.username}`}
            target="_blank"
            rel="noopener noreferrer"
            aria-label="Connect with Us on LinkedIn"
          >
            {/* <i className={`fa fa-${social.networkName}`}/> */}
            <AiOutlineLinkedin className="linkedin-ico-contact" />
          </a>
        ))}
      </div>
    </Fragment>
  );
};

export default ContactInfo;

import React from 'react';

function FeatureItem(props) {
    console.log(props)
    return (
        
        <div className="col-md-4">
            
            <div className="icon-box-item icon-box-item-company-overview">
                <div className="icon-box__icon icon-box__icon-2">
                    <img width="80" height="74" src={require('../../assets/img/' + props.img)} alt="cmu-core-domains-of-epertise"/>
                </div>
                <div className="icon-box__info">
                    <h3>{props.title}</h3>
                    <p>{props.text}</p>
                </div>
                
            </div>
        </div>
    );
}

export default FeatureItem;
import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import './index.scss';
// import * as serviceWorker from './serviceWorker';
import { HelmetProvider, Helmet } from 'react-helmet-async';

const root = ReactDOM.createRoot(document.getElementById('root'));

root.render(
  <React.StrictMode>
    <HelmetProvider>
      <Helmet>
        <meta http-equiv="Content-Security-Policy" content="upgrade-insecure-requests" />
        <script defer src="https://cms.codemeup.net/umami/script.js" data-website-id="0cac30cd-acce-4cdc-be98-b5d572310563"></script>
      </Helmet>
      <App />
    </HelmetProvider>
  </React.StrictMode>
);

// import React from 'react';
// import ReactDOM from 'react-dom';
// import App from './App';
// import './index.scss'
// import * as serviceWorker from './serviceWorker';

// ReactDOM.render(<App />, document.getElementById('root'));

// // If you want your app to work offline and load faster, you can change
// // unregister() to register() below. Note this comes with some pitfalls.
// // Learn more about service workers: https://bit.ly/CRA-PWA
// serviceWorker.unregister();
import React from 'react';
import {Link} from "react-router-dom";
import error from '../assets/img/planet-stars.png'
import bgImg from '../assets/img/space-404-background.jpg'


const Error404 = () => {
    return (
        <div style={{ backgroundImage: `url(${bgImg})`, backgroundRepeat: 'no-repeat', backgroundPosition: 'center', backgroundSize: 'cover' }}>
        <div style={styles}>
            <div className="error_404_div">
                <img src={error} alt="404"/>
                <p className="error-paragraph">Hmm, looks like that page doesn't exist.</p>
                <Link to="/" className={'btn-brand'} style={{display: 'inline-Block', marginTop: 30}}>Go Back
                    Home</Link>
            </div>
        </div>
        </div>
    );
};


const styles = {
    maxWidth: 1200,
    margin: 'auto',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100vh',
    textAlign: 'center',
    // padding: 30
};

export default Error404;
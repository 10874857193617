import React, { Fragment } from 'react';
import Header from "../components/Header";
import PageHeader from "../components/PageHeader";
// import About from "../components/About/home-two";
// import Services from "../components/Services";
// import BrandLogo from "../components/BrandLogo";
// import Funfact from "../components/Funfact";
// import CallToAction from "../components/CallToAction";
import Footer from "../components/Footer";
// import LoginRegister from "../components/LoginRegister";
import MobileMenu from "../components/MobileMenu";
// import Features from "../components/Features";
// import Testimonial from "../components/Testimonials/home-two";
// import Team from "../components/Team/home-two";
// import ServiceThumb from '../assets/img/about.jpg'

// import VerticalTab from '../components/VerticalTabs/VerticalTab';
// import resumeData from "../components/VerticalTabs/resumeData.js";
import dividerImg from '../assets/img/cmu-h-divider.jpg';

const PageAbout = () => {
	return (
		<Fragment>
			<Header />
			<PageHeader
				// bgImg={require('../assets/img/page-header.jpg')}
				title="ABOUT US"
				content="Businex always try to provide the best Business Solutions for Clients to grow up their Business very sharply and smoothly."
			/>
			{/* <About
                title={'Our Team'}
                heading="Meet Our <br/> Expert Member"
                thumb={ServiceThumb}
                content="<b>Businex</b> always try to provide the best Business Solutions for Clinets to grow up their Business very sharply and smoothly. We voluptatem quia voluptas sit aspernatur aut odit aut fugit, sed quia consequuntur magni dolores eos qui ratione voluptatem sequi nesciunt."
            /> */}
			{/* <Services/>
            <Features classes={'sm-top'}/>
            <Testimonial/>
            <Team/>
            <BrandLogo/>
            <Funfact classes="sp-top"/>
            <CallToAction/> */}
			
			{/* <div className="col-xl-4_5 btn-hero-div"><Link to={`${process.env.PUBLIC_URL + item.btnLink}`} className="btn btn-hero-explore">{item.btnText}<CgArrowLongRight className="arrow-icon"/></Link></div> */}


			<div className="about-area-wrapper sm-top about-page-space">
            <div className="container">
                <div className="row align-items-lg-center">
                <div className="col-md-12 col-lg-12">
				<h2 className="">Our story</h2>
				<div className="heading-divider-image-div"><img width="120" height="7" className="divider-image" src={dividerImg} alt="cmu-divider" /></div>
				<p>Code Me Up is a global consulting and software engineering company that empowers the world's prominent  IT companies with innovative  and sophisticated engineering services.
				<br />
				<br />
				Founded in 2021 in Belgrade, Serbia with consultancy offices in New York and London, with numerous development centers in Serbia and Thailand.
				<br />
				<br />
				The company’s area of work spans a multitude of domains with most of the focus going towards Media including Android and digital TV in general.
				<br />
				<br />
				The company approaches its clients with impetus to not just solve challenges but create future opportunities, while providing the ultimate growth platform for its talents.
				<br />
				<br />
				With the breadth of its expertise and the size of its talent pool in regard to Android and TV endeavors, Code Me Up is able to tackle even the most pressing technological challenges of today and partner with the boldest companies on the planet in pushing the boundaries of technology and turning the implausible into everyday.</p>
                   
					</div>
                
                    
                    
                </div>
            </div>
        </div>
		{/* <VerticalTab classname="vertical-tabs-about" data={resumeData.projects} /> */}


			<Footer />
			{/* <LoginRegister /> */}
			<MobileMenu />
		</Fragment>
	);
};

export default PageAbout;
import React from 'react';
import Feature from './FeatureItem'
import FeaturesData from '../../data/Features/features'
import dividerImg from '../../assets/img/cmu-h-divider.jpg';
import { FaArrowRight } from 'react-icons/fa';

function Features({classes}) {
    return (
        <div className={`feature-area-wrapper ${classes}`}>
            <div className="container">
                {/* <div className="row mtn-sm-60 mtn-md-5 company-overview-row sm-top-mobile"> */}
                <div className="row mtn-md-5 company-overview-row">
                <h2>Core Domains of Expertise</h2>
                <div className="heading-divider-image-div"><img width="120" height="7" className="divider-image" src={dividerImg} alt="cmu-divider" /></div>
                    {
                        FeaturesData.map(feature=>(
                            <Feature key={feature.id} title={feature.title} text={feature.text} img={feature.imgSrc} />
                        ))
                    }
                </div>
            </div>
        </div>
    );
}

export default Features;
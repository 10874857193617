import React from 'react';
import Feature from './FeaturesItem'
import FeaturesData from '../../data/Features/services.json'
import dividerImg from '../../assets/img/cmu-h-divider.jpg';
import { FaArrowRight } from 'react-icons/fa';

function Features({classes}) {
    return (
        <div className={`feature-area-wrapper sm-top-services-page-features  ${classes}`}>
            <div className="container">
                <div className="row mtn-sm-60 mtn-md-5 services-features-row">
                {/* <h2>Lorem Ipsum</h2> */}
                <div className="heading-divider-image-div"><img width="120" height="7" className="divider-image" src={dividerImg} alt="cmu-divider" /></div>
                    {
                        FeaturesData.map(feature=>(
                            <Feature key={feature.id} title={feature.title} text={feature.text} img={feature.imgSrc} />
                        ))
                    }
                </div>
            </div>
        </div>
    );
}

export default Features;